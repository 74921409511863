const loadUserProfile = () => {
	if (typeof window !== 'undefined') {
		const storedProfile = localStorage.getItem('userProfile')
		return storedProfile ? JSON.parse(storedProfile) : {
			name: 'John Doe',
			likedProducts: [],
			purchasedProducts: [],
			likedCompanies: [],
		}
	}
		return {
			name: 'John Doe',
			likedProducts: [],
			purchasedProducts: [],
			likedCompanies: [],
	}
}

const saveUserProfile = (profile) => {
	if (typeof window !== 'undefined') {
		localStorage.setItem('userProfile', JSON.stringify(profile))
	}
}

const userProfile = ref(loadUserProfile())

watch(userProfile, (newProfile) => {
	saveUserProfile(newProfile)
}, { deep: true, immediate: true })

const toggleLikedProduct = (productId) => {
	const index = userProfile.value.likedProducts.indexOf(productId)
	if (index > -1) {
		userProfile.value.likedProducts.splice(index, 1)
	} else {
		userProfile.value.likedProducts.push(productId)
	}
}

const togglePurchasedProduct = (productId) => {
	const index = userProfile.value.purchasedProducts.indexOf(productId)
	if (index > -1) {
		userProfile.value.purchasedProducts.splice(index, 1)
	} else {
		userProfile.value.purchasedProducts.push(productId)
	}
}

const toggleLikedCompany = (companyId) => {
	const index = userProfile.value.likedCompanies.indexOf(companyId)
	if (index > -1) {
		userProfile.value.likedCompanies.splice(index, 1)
	} else {
		userProfile.value.likedCompanies.push(companyId)
	}
}

export function useUsers() {
	const getUserProfile = () => {
		return userProfile.value
	}
	return {
		getUserProfile,
		toggleLikedProduct,
		togglePurchasedProduct,
		toggleLikedCompany,
		userProfile
	}
}
